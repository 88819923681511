import { ActionFunctionArgs, LoaderFunctionArgs, json } from '@remix-run/node';
import {
  Form,
  Link,
  useActionData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from '@remix-run/react';

import { Button } from '~/components/ui/button';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';
import { MaxWidth } from '~/components/ui/max-width';
import { PageDescription, PageHeader, PageHeaderGroup } from '~/components/ui/page-header.tsx';
import { Separator } from '~/components/ui/separator';

import { requireAnonymous } from '~/utils/authentication/authentication.server';
import { invalidFormSubmission } from '~/utils/conform/conform-helper.server.ts';
import {
  FormActionResponse,
  getSafeInputProps,
  useLastResult,
} from '~/utils/conform/conform-helper.ts';
import { createEntityId } from '~/utils/db/entity-id';
import { isLoading } from '~/utils/general/is-loading.ts';
import { getAuthenticationSession } from '~/utils/session/authentication.server';

import { handleEmailLogin, handleSocialLogin } from '~/modules/login/actions.server.ts';
import { emailLoginSchema, socialLoginSchema } from '~/modules/login/schemas.ts';

import { useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { redirectWithError } from 'remix-toast';
import { z } from 'zod';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await requireAnonymous(request);
  return null;
};

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: z.discriminatedUnion('intent', [emailLoginSchema, socialLoginSchema]),
  });
  if (submission.status !== 'success') {
    return invalidFormSubmission(submission);
  }
  const { value, ...rest } = submission;
  switch (value.intent) {
    case 'emailLogin': {
      return handleEmailLogin({
        context,
        submission: { value, ...rest },
        request,
      });
    }
    case 'socialLogin': {
      return handleSocialLogin({
        submission: { value, ...rest },
        context,
        request,
      });
    }
  }
};
const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const sent = searchParams.get('sent') === 'true';

  const prefilledEmail = searchParams.get('prefill');

  const { t } = useTranslation();

  return (
    <div className={'h-screen bg-sidebar flex w-full justify-center items-center p-2'}>
      <div
        className={'rounded-3xl p-5 md:p-10 bg-background flex flex-col items-center shadow gap-4'}>
        <DashboardIcon />
        <div className={'text-center'}>
          <PageHeaderGroup>
            <PageHeader className={'font-instrument text-4xl'}>{t('login.title')}</PageHeader>
            <PageDescription>{t('login.description')}</PageDescription>
          </PageHeaderGroup>
        </div>
        {sent ? <EmailSent /> : <EmailLogin defaultValue={prefilledEmail ?? ''} />}
        <span
          className={
            'flex flex-col gap-4 items-center justify-center text-xs text-muted-foreground'
          }>
          <p className={'max-w-md text-center'}>{t('login.disclaimer')}</p>
          <span className={' underline'}>
            <Link to={'https://triargos.de/impressum/'} target={'_blank'} rel='noreferrer'>
              {t('login.imprint')}
            </Link>
          </span>
        </span>
      </div>
    </div>
  );
};

const EmailLogin = ({ defaultValue }: { defaultValue: string }) => {
  const actionData = useActionData<FormActionResponse>();
  const lastResult = useLastResult(actionData);
  const navigation = useNavigation();
  const { t } = useTranslation('login');

  const [form, fields] = useForm({
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: emailLoginSchema });
    },
    defaultValue: {
      intent: 'emailLogin',
      email: defaultValue ?? '',
    },
    lastResult,
  });
  return (
    <Form id={form.id} onSubmit={form.onSubmit} method={'post'} className={'mt-4 w-full space-y-4'}>
      <input type={'hidden'} name={fields.intent.name} value={fields.intent.initialValue} />
      <span className={'grid gap-2'}>
        <Label>{t('form.email.label')}</Label>
        <Input
          className={'h-11'}
          {...getSafeInputProps(fields.email)}
          placeholder={t('form.email.placeholder')}></Input>
      </span>
      <Button
        isLoading={isLoading(navigation, 'emailLogin')}
        size={'lg'}
        rounded={'full'}
        className={'w-full'}>
        {t('action')}
      </Button>
    </Form>
  );
};

const EmailSent = () => {
  const { t } = useTranslation('login');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const navigate = useNavigate();
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('channels:login');
    broadcastChannel.onmessage = (event) => {
      if (event.data?.message === 'login.complete') {
        broadcastChannel.postMessage({ message: 'redirect.confirm' });
        const redirectHint = event.data?.redirectHint ?? '/';
        navigate(redirectHint, { replace: true });
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, []);

  return (
    <div className={'text-center p-5 border rounded-2xl'}>
      <h2 className={'font-medium'}>{t('sent.title')}</h2>
      <p className={'text-sm text-muted-foreground max-w-md'}>{t('sent.description', { email })}</p>
    </div>
  );
};

export default LoginPage;

const DashboardIcon = () => {
  return (
    <div
      className={
        'rounded-md bg-primary h-8 w-8 flex items-center justify-center text-primary-foreground font-medium text-sm'
      }>
      DB
    </div>
  );
};
